<template>
  <div class="footer-container" v-if="$store.state.READY">
    <div class="row">
      <div class="col-33 flex-left">
        <div class="footer-bloc">
          <div class="footer-title">
            {{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.legal }}
          </div>
          <nav>
            <!-- <a
              class="footer-link"
              :href="legal"
              target="_blank"
              >
              {{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.legalNotice }}
            </a 
            > -->
            <a class="footer-link" :href="legal" target="_blank" >
              {{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.privacy }}
            </a>
            <!-- <a
              class="footer-link"
              :href="legal"
              target="_blank"
              >
              {{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.cookies }}
              </a
            > -->
          </nav>
        </div>
      </div>
      <div class="col-33 flex-center">
        <div class="footer-bloc">
          <div class="footer-title">
            {{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.connect }}
          </div>
          <nav>
            <a
              class="footer-link"
              :href="about"
              target="_blank"
              >
              {{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.about }}
              </a
            >
            <a
              class="footer-link"
              :href="contact"
              target="_blank"
              >
              {{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.contact }}
              </a
            >
          </nav>
        </div>
      </div>
      <div class="col-33 flex-right">
        <div class="footer-bloc">
          <div class="footer-title">
            {{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.follow }}
          </div>
          <div class="social-network">
            <a
              :href="facebook"
              target="_blank"
              class="social-network-item icon-otw_icon_facebook"
            ></a>
            <a
              :href="instagram"
              target="_blank"
              class="social-network-item icon-otw_icon_instagram"
            ></a>
            <a
              :href="linkedin"
              target="_blank"
              class="social-network-item icon-otw_icon_linkedin"
            ></a>
            <a
              :href="twitter"
              target="_blank"
              class="social-network-item icon-otw_icon_twitter"
            ></a>
            <a
              :href="youtube"
              target="_blank"
              class="social-network-item icon-otw_icon_youtube"
            ></a>
          </div>

          
        </div>
      </div>
    </div>
    <div class="footer-infos">
      <div class="footer-logo">
        <p>
          {{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.credential}}
        </p>
      <!-- FOOTER TYPE 1 -->
      
      <a v-if="this.$store.state.CONFIG.footerType == 1" href="https://otwvirtualconventions.com/" target="_blank">
        <img src="./../assets/img/logo-footer-login.png" alt="logo footer" />
      </a>
      <!-- FOOTER TYPE 2 ou 3 -->
       <a v-else href="https://home.my-virtual-convention.com/" target="_blank">
        <img src="./../assets/img/logo_footer_2.png" alt="logo footer" />
      </a>
      <a href="https://www.celaprod.com/" target="_blank" v-if="this.$store.state.CONFIG.footerType == 3">{{ this.$store.state.TRANSLATE.COMPONENTS.footerApp.celaprod }}</a>
      </div>
      <div class="footer-sep"></div>
      <div class="footer-copyright">
        <p>
          {{ this.$store.state.CONFIG.footerType == 1 ?  `© ${new Date().getFullYear()} ${this.$store.state.TRANSLATE.COMPONENTS.footerLight.copyright}` :  `© ${new Date().getFullYear()} ${this.$store.state.TRANSLATE.COMPONENTS.footerLight.copyrightAlt}` }}

        </p>
      </div>
    </div>
    <div class="footer-version">v1.1.01</div>
  </div>
</template>

<script>
export default {
  name: "FooterApp",
  data(){
    return {
      facebook : null,
      linkedin : null,
      youtube : null,
      instagram : null,
      twitter : null,
      about : null,
      contact : null,
      legal : null
    }
  },
  created() {
    if(this.$store.state.CONFIG.footerType == 1) {
      this.facebook = 'https://www.facebook.com/ahlstromglobal/'
      this.linkedin = 'https://www.linkedin.com/company/11079792/'
      this.youtube = 'https://www.youtube.com/@AhlstromGlobal'
      this.instagram = 'https://www.instagram.com/ahlstromglobal/'
      this.twitter = 'https://twitter.com/AhlstromGlobal'
      this.about = 'https://www.ahlstrom.com/'
      this.contact = 'https://www.ahlstrom.com/Contact-us/'
      this.legal = 'https://www.ahlstrom.com/privacy-policy/'
    }else if(this.$store.state.CONFIG.footerType == 2) {
      this.facebook = 'https://www.facebook.com/ahlstromglobal/'
      this.linkedin = 'https://www.linkedin.com/company/11079792/'
      this.youtube = 'https://www.youtube.com/@AhlstromGlobal'
      this.instagram = 'https://www.instagram.com/ahlstromglobal/'
      this.twitter = 'https://twitter.com/AhlstromGlobal'
      this.about = 'https://www.ahlstrom.com/'
      this.contact = 'https://www.ahlstrom.com/Contact-us/'
      this.legal = 'https://www.ahlstrom.com/privacy-policy/'
    } else if (this.$store.state.CONFIG.footerType == 3){
      this.facebook = 'https://www.facebook.com/ahlstromglobal/'
      this.linkedin = 'https://www.linkedin.com/company/11079792/'
      this.youtube = 'https://www.youtube.com/@AhlstromGlobal'
      this.instagram = 'https://www.instagram.com/ahlstromglobal/'
      this.twitter = 'https://twitter.com/AhlstromGlobal'
      this.about = 'https://www.ahlstrom.com/'
      this.contact = 'https://www.ahlstrom.com/Contact-us/'
      this.legal = 'https://insight.my-virtual-convention.com/legal/'
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-container {
  background: #1b1b1b;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 140px;
  position: relative;
}

.footer-container .row {
  width: $box-width;
  max-width: 95%;
  padding: 30px 0 10px 0;
}

.footer-title {
  color: #fff;
  font-size: 15px;
  font-family: $font-bold;
  font-weight: 700;
  margin-bottom: 20px;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.footer-bloc {
  padding: 0 5%;
  width: 90%;
}

.footer-bloc nav {
  display: flex;
  flex-direction: column;
}

.footer-bloc nav a {
  color: #fff;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 15px;
  cursor: pointer;
}

.footer-bloc nav a:hover {
  text-decoration: underline;
}

/* footer infos */

.footer-infos {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  align-items: center;
}

.footer-copyright p {
  color: #fff;
  font-size: 15px;
}

.footer-logo img {
  max-height: 60px;
}

.footer-sep {
  margin: 0 40px;
  width: 1px;
  background: #fff;
  height: 40px;
}

.social-network {
  display: flex;
}

.social-network-item {
  margin: 0 5px;
}

.social-network-item:before {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}
.social-network-item:hover:before {
  color: $main-color;
}

.social-network-item:first-child {
  margin-left: 0;
}

.footer-logo {
  display: flex;
  flex-direction: column;
}

.footer-logo p {
  font-size:12px;
  color:#fff;
  margin-bottom: 5px;
  text-align: center;
}

.footer-logo a {
  font-size:12px;
  color:#fff;
  text-align: center;
  text-decoration: none;
  font-style:italic;
  margin-top:5px;
}

.footer-logo a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .footer-container .row {
    flex-direction: column;
    align-items: center;
  }

  .footer-container .row  .col-33 {
    margin-bottom: 30px;
    width: 100%;

  }

  .footer-bloc nav {
    align-items: center;
  }

  .footer-bloc {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .footer-infos{
    flex-direction: column;
  }

  .footer-sep {
    display: none;
  }

  .footer-copyright {
    margin-top: 10px;
  }
}
</style>
