import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VTooltip from "v-tooltip";
import VueYoutube from "vue-youtube";
import VCalendar from "v-calendar";
import "./vee-validate";
import axios from "axios";
import main_color from "./variables.scss";
import VueGtag from 'vue-gtag'
Vue.use(VCalendar);

Vue.use(VueGtag, {
  config: {
      // id: ['G-9JQLJEFRBR', 'G-7CHB6WB6FV'] ,
      id : 'G-435RQ1TJDE'
  }
}, router)

//chat
import VueSocketIO from 'vue-socket.io';
const options = {
  'reconnection': true,
  'reconnectionDelay': 500,
  'reconnectionAttempts': 10
}

Vue.use(new VueSocketIO({
  debug: true,
  //connection: 'http://localhost:3000',
  //connection : 'https://chat-mvc.herokuapp.com/',
  connection : 'https://chat-beta.my-virtual-convention.com',
  credentials: true,
  options,
  vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
}))


// Vue.prototype.$SERVER_URL = SERVER_URL;
Vue.prototype.$axios = axios;
Vue.prototype.$main_color = main_color.main_color;
axios.interceptors.response.use(null, (error) => {
  if (error.response.status && error.response.status === 403) {
    if (router.currentRoute.path != "/login") {
      let infos = null
      if(store.state.LANG == 'FR') {        
        infos.title = 'Session expirée',
        infos.text = 'Votre session a expiré. Merci de vous connecter à nouveau.'
      }else {
        infos.title = 'Expired session',
        infos.text = 'Your session has expired. Please log in again.'
      }
      store.commit('openModalInfos', infos )
      store.commit("logout");
    } else {
      return "failed";
    }
  }
});

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.use(VueYoutube);

new Vue({
  router: router,
  store,
  render: (h) => h(App),
}).$mount("#app");
