<template>
  <div class="header-container" v-if="$store.state.READY">
    <div class="header-box">
      <router-link
        to="/lobby"
        class="logo-header"
        v-if="this.$store.state.CONFIG.lobby"
      >
        <img
          :src="
            this.$store.state.BASE_URL +
            '/file/' +
            this.$store.state.CONVENTION.logo_header
          "
          alt="main logo"
        />
      </router-link>
      <router-link to="/" class="logo-header" v-else>
        <img
          :src="
            this.$store.state.BASE_URL +
            '/file/' +
            this.$store.state.CONVENTION.logo_header
          "
          alt="main logo"
        />
      </router-link>

      <div class="menu-header">
        <nav class="menu-container">

          <router-link
            to="/lobby"
            class="menu-item"
            v-if="this.$store.state.CONFIG.lobby"
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_home"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.home }}
            </div>
          </router-link>
          <router-link to="/" class="menu-item">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_booth2"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.exhibitHall }}
            </div>
          </router-link>
          <!-- Lien lectures Hall dans l'app -->
          <template v-if="this.$store.state.CONFIG.lecturesHallUrl != '0'">
            <router-link
              to="/lecture-halls"
              class="menu-item"
              v-if="this.$store.state.CONFIG.lecturesHallUrl == '1'"
            >
              <div class="menu-highlight"></div>
              <div class="icon-menu icon-otw_icon_lecturehall"></div>
              <div class="menu-text">
                {{
                  this.$store.state.TRANSLATE.COMPONENTS.headerApp.lecturesHall
                }}
              </div>
            </router-link>
            <!-- Lien lectures Hall externe -->
            <a
              :href="this.$store.state.CONFIG.lecturesHallUrl"
              target="_blank"
              class="menu-item"
              v-else
            >
              <div class="menu-highlight"></div>
              <div class="icon-menu icon-otw_icon_lecturehall"></div>
              <div class="menu-text">
                {{
                  this.$store.state.TRANSLATE.COMPONENTS.headerApp.lecturesHall
                }}
              </div>
            </a>
          </template>
          <router-link
            to="/guest-speaker"
            class="menu-item"           
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_guest_speaker"></div>

            <div class="menu-text">
              Guest Speakers
            </div>
          </router-link>
            <router-link
            to="/technical-sessions"
            class="menu-item"
            v-if="this.$store.state.CONFIG.technicalHall"
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_conference-tech"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.technical }}
            </div>
          </router-link>
          <router-link
            to="/matchmaking"
            class="menu-item"
            v-if="this.$store.state.CONFIG.networking"
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_matchmaking"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.networking }}
            </div>
          </router-link>
          <router-link to="/my-bookmarks" class="menu-item" v-if="this.$store.state.CONFIG.bookmark">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_bookmark"></div>
            <div class="menu-text">{{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.bookmark }}</div>
          </router-link>
          <router-link to="/my-calendar" class="menu-item" v-if="this.$store.state.CONFIG.calendar">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_calendar"></div>
            <div class="menu-text">{{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.calendar }}</div>
          </router-link>
          <router-link to="/chat" class="menu-item" v-if="this.$store.state.CONFIG.chat">
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_tchat"></div>
             <div class="chat-notif" v-if="getChatNotifs && getChatNotifs > 0">{{ getChatNotifs > 99 ? 99 : getChatNotifs }}</div>
            <div class="menu-text">
               {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.chat }}
            </div>
          </router-link>
          <router-link
            to="/appointments"
            class="menu-item"
            v-if="this.$store.state.CONFIG.appointments"
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_appointment"></div>

            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.appointments }}
            </div>
          </router-link>
          <router-link
            to="/wheel"
            class="menu-item"           
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_lotery"></div>

            <div class="menu-text">
              WHEEL OF FORTUNE
            </div>
          </router-link>
          <router-link
            to="/video-tutorial"
            class="menu-item"           
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_watch"></div>

            <div class="menu-text">
              HOW TO USE<br /> THE PLATFORM
            </div>
          </router-link>
          <router-link
            to="/collective-fresco"
            class="menu-item"           
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_paint"></div>

            <div class="menu-text">
              COLLECTIVE FRESCO
            </div>
          </router-link>

        </nav>

        <div class="menu-user" @click.stop="switchMenu">
          <div class="menu-user-main">
            <div
              class="avatar"
              v-if="avatar"
              :style="
                'background:url(' +
                this.$store.state.BASE_URL +
                '/file/' +
                avatar +
                '?t=' +
                this.$store.state.token +
                ')'
              "
            >
              <!-- <img :src="this.$store.state.BASE_URL+ '/file/' + avatar + '?t='+this.$store.state.token" alt="avatar" /> -->
            </div>
            <div
              v-else
              class="avatar-default"
              :style="{ background: this.$store.state.AVATAR_COLOR }"
            >
              <span
                v-if="
                  user.firstname &&
                  user.lastname &&
                  user.firstname.length > 0 &&
                  user.lastname.length > 0
                "
                >{{
                  user.firstname.substr(0, 1) + user.lastname.substr(0, 1)
                }}</span
              >
              <span v-else>XY</span>
            </div>
            <div class="user-infos">
              <div
                class="user-name"
                v-if="user.firstname && user.firstname.length > 0"
              >
                {{ user.firstname }}
              </div>
              <div class="user-name" v-else>Xxx</div>
              <div class="user-company">{{ user.company }}</div>
            </div>
            <div
              :class="['toggle-menu', { reverse: this.$store.state.USERMENU }]"
            ></div>
          </div>
          <div class="menu-user-sub" v-if="this.$store.state.USERMENU">
            <router-link to="/my-profile">
              <div class="submenu-item">
                <div class="icon-submenu icon-otw_icon_profile"></div>
                <div class="submenu-text">
                  {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.profile }}
                </div>
              </div>
            </router-link>
            <router-link to="/my-settings">
              <div class="submenu-item">
                <div class="icon-submenu icon-otw_icon_settings"></div>
                <div class="submenu-text">
                  {{
                    this.$store.state.TRANSLATE.COMPONENTS.headerApp.settings
                  }}
                </div>
              </div>
            </router-link>
            <a
              :href="
                'mailto:dedicoated@ahlstrom.com?subject=' +
                this.$store.state.TRANSLATE.COMPONENTS.headerApp.help +
                ' - ' +
                this.$store.state.CONVENTION.name
              "
              class="submenu-item"
              target="_blank"
            >
              <div class="icon-submenu icon-otw_icon_contact_support"></div>
              <div class="submenu-text">
                {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.support }}
              </div>
            </a>
            <div class="submenu-item logout-item" @click="logout">
              <div class="icon-submenu icon-otw_icon_logout"></div>
              <div class="submenu-text">
                {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.logOut }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- MENU RESP -->
      <div class="menu-header-resp">
        <div
          class="burger-nav"
          @click="changeState"
          :class="open ? 'open' : ''"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        <div class="chat-notif chat-notif-burger" v-if="getChatNotifs && getChatNotifs > 0">{{ getChatNotifs }}</div>

        </div>
        <nav class="menu-header-resp-content" v-if="open">
          <router-link to="/lobby" class="menu-item">
            <div class="icon-menu icon-otw_icon_home"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.home }}
            </div>
          </router-link>
          <router-link to="/" class="menu-item">
            <div class="icon-menu icon-otw_icon_booth2"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.exhibitHall }}
            </div>
          </router-link>
          <!-- Lien lectures Hall dans l'app -->
          <template v-if="this.$store.state.CONFIG.lecturesHallUrl != '0'">
            <router-link
              to="/lecture-halls"
              class="menu-item"
              v-if="this.$store.state.CONFIG.lecturesHallUrl == '1'"
            >
              <div class="icon-menu icon-otw_icon_lecturehall"></div>
              <div class="menu-text">
                {{
                  this.$store.state.TRANSLATE.COMPONENTS.headerApp.lecturesHall
                }}
              </div>
            </router-link>
            <!-- Lien lectures Hall externe -->
            <a
              :href="this.$store.state.CONFIG.lecturesHallUrl"
              target="_blank"
              class="menu-item"
              v-else
            >
              <div class="icon-menu icon-otw_icon_lecturehall"></div>
              <div class="menu-text">
                {{
                  this.$store.state.TRANSLATE.COMPONENTS.headerApp.lecturesHall
                }}
              </div>
            </a>
          </template>
          <router-link
            to="/guest-speaker"
            class="menu-item"           
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_guest_speaker"></div>

            <div class="menu-text">
              Guest Speakers
            </div>
          </router-link>
          <router-link
            to="/technical-sessions"
            class="menu-item"
            v-if="this.$store.state.CONFIG.technicalHall"
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_conference-tech"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.technical }}
            </div>
          </router-link>
          <router-link
            to="/matchmaking"
            class="menu-item"
            v-if="this.$store.state.CONFIG.networking"
          >
            <div class="icon-menu icon-otw_icon_matchmaking"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.networking }}
            </div>
          </router-link>
          <router-link to="/my-bookmarks" class="menu-item" v-if="this.$store.state.CONFIG.bookmark">
            <div class="icon-menu icon-otw_icon_bookmark"></div>
            <div class="menu-text">{{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.bookmark }}</div>
          </router-link>
          <router-link to="/my-calendar" class="menu-item" v-if="this.$store.state.CONFIG.calendar">
            <div class="icon-menu icon-otw_icon_calendar"></div>
            <div class="menu-text">{{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.calendar }}</div>
          </router-link>
          <router-link to="/chat" class="menu-item"  v-if="this.$store.state.CONFIG.chat">
            <div class="icon-menu icon-otw_icon_tchat"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.chat }}
            </div>
            <div class="chat-notif chat-notif-resp" v-if="getChatNotifs && getChatNotifs > 0">{{ getChatNotifs }}</div>
          </router-link>
          <router-link
            to="/appointments"
            class="menu-item"
            v-if="this.$store.state.CONFIG.appointments"
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_appointment"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.appointments }}
            </div>
          </router-link>
          <router-link
            to="/wheel"
            class="menu-item"           
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_lotery"></div>

            <div class="menu-text">
              WHEEL OF FORTUNE
            </div>
          </router-link>
          <router-link
            to="/video-tutorial"
            class="menu-item"           
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_watch"></div>

            <div class="menu-text">
              HOW TO USE THE PLATFORM
            </div>
          </router-link>
          <router-link
            to="/collective-fresco"
            class="menu-item"           
          >
            <div class="menu-highlight"></div>
            <div class="icon-menu icon-otw_icon_paint"></div>

            <div class="menu-text">
              COLLECTIVE FRESCO
            </div>
          </router-link>

          <div class="menu-resp-sep"></div>

          <router-link to="/my-profile" class="menu-item">
            <div class="icon-menu icon-otw_icon_profile"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.profile }}
            </div>
          </router-link>

          <router-link to="/my-settings" class="menu-item">
            <div class="icon-menu icon-otw_icon_settings"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.settings }}
            </div>
          </router-link>

          <a
            :href="
              'mailto:dedicoated@ahlstrom.com?subject= ' +
              this.$store.state.TRANSLATE.COMPONENTS.headerApp.help +
              ' - ' +
              this.$store.state.CONVENTION.name
            "
            class="menu-item"
            target="_blank"
          >
            <div class="icon-menu icon-otw_icon_contact_support"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.support }}
            </div>
          </a>

          <div class="menu-resp-sep"></div>

          <div class="menu-item menu-resp-logout" @click="logout">
            <div class="icon-menu icon-otw_icon_logout"></div>
            <div class="menu-text">
              {{ this.$store.state.TRANSLATE.COMPONENTS.headerApp.logOut }}
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderApp",
  data() {
    return {
      // firstname: this.$store.getters.getUser.firstname,
      // lastname: this.$store.getters.getUser.lastname,
      // company: this.$store.getters.getUser.company,
      bgColor: null,
    };
  },
  methods: {
    logout() {

      //delete from stand visitor list
      if(this.$store.state.USER.stand != this.$route.params.id && this.$route.name == 'Stand') {
        if(this.$store.state.USER.is_member != 1) {
          const visitor = {
            stand : this.$route.params.id,
            userId : this.$store.state.USER.id
          }
          this.$socket.emit("leaveStand", visitor);
        }
      }

      const id = this.$store.getters.getAttendees.filter(
        (el) =>
          el.firstname == this.user.firstname &&
          el.lastname == this.user.lastname
      )[0].id;
      if (id) {
        const user = {
          uid : this.$store.state.USER.id,
          convention : this.$store.state.CONVENTION.id
        }
        this.$socket.emit("logout", user);
      }
      this.$store.dispatch("makeUserOffline");

      this.$store.commit("logout");


    },
    switchMenu() {
      this.$store.commit("switchUserMenu");
    },
    changeState() {
      this.$store.commit("setMenuOpen");
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    open() {
      return this.$store.getters.getMenuOpen;
    },
    avatar() {
      return this.$store.getters.getUser.avatar;
    },
    alert() {
      return this.$store.getters.getAlertNewMsg;
    },
    getChatNotifs() {
      let notifs = 0;
      this.$store.state.ROOMS.forEach(element => {
        if(element.sender == this.$store.state.USER.id) {
           notifs = notifs + element.senderWaitingMsg;
        }else {
            notifs = notifs + element.recieverWaitingMsg;
        }
      });
      return notifs;
    }
  },
};
</script>

<style lang="scss" scoped>
.msg-notification {
  width: 18px;
  height: 18px;
  border-radius: 50px;
  font-size: 10px;
  color: #fff;
  line-height: 19px;
  text-align: center;
  background: $main-color;
  position: fixed;
  margin: -48px 5px 8px 70px;
}
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  background: #fff;
  box-shadow: 0 2px 10px 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  width: $box-width;
  max-width: 95%;
}

.logo-header {
  display: flex;
  align-items: center;
}

.logo-header img {
  max-height: 65px;
}

.menu-header {
  display: flex;
  justify-content: flex-end;
}

.menu-container {
  display: flex;
}

.icon-menu:before {
  font-size: 22px;
  color: $menu-color;
}

.icon-menu.icon-otw_icon_appointment:before {
  font-size:27px;
}

.menu-item:hover .icon-menu:before,
.router-link-exact-active .icon-menu:before {
  color: $main-color;
}

.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  border-left: 1px solid $menu-color;
  height: 85px;
  position: relative;
  cursor: pointer;
}

.menu-item:last-child {
  border-right: 1px solid $menu-color;
}

.menu-highlight {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: #fff;
}

.menu-item:hover .menu-highlight,
.router-link-exact-active .menu-highlight {
  background: $main-color;
}

.menu-text {
  display: none;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: $main-color;
  font-size: 10px;
  font-display: $font-bold;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.menu-item:hover .menu-text,
.router-link-exact-active .menu-text {
  display: flex;
}

/* MENU RESP */
.menu-header-resp {
  display: none;
}

.burger-nav {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.burger-nav span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: $main-color;
  border-radius: 7px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.burger-nav span:nth-child(1) {
  top: 0px;
}

.burger-nav span:nth-child(2),
.burger-nav span:nth-child(3) {
  top: 14px;
}

.burger-nav span:nth-child(4) {
  top: 28px;
}

.burger-nav.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.burger-nav.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burger-nav.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burger-nav.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu-header-resp-content {
  position: fixed;
  top: 85px;
  width: 300px;
  right: 0;
  background: #fff;
  border-bottom: 1px solid $menu-color;
}

.menu-header-resp-content .menu-item {
  width: calc(100% - 40px);
  border: none;
  height: 40px;
  justify-content: flex-start;
  padding: 0 20px;
  align-items: center;
  text-decoration: none;
}

.menu-header-resp-content .menu-item .menu-text {
  display: flex;
  position: relative;
  width: calc(100% - 22px);
  height: 40px;
  align-items: center;
  bottom: 0;
  text-decoration: none;
  text-align: left;
  justify-content: flex-start;
  margin-left: 20px;
}

.menu-header-resp-content .router-link-exact-active.menu-item {
  background: $main-color;
}

.menu-header-resp-content .router-link-exact-active .menu-highlight {
  display: none;
}

.menu-header-resp-content .router-link-exact-active.menu-item .icon-menu:before,
.menu-header-resp-content .router-link-exact-active.menu-item .menu-text {
  color: #fff;
}

.menu-resp-sep {
  margin: 5px 0;
  height: 1px;
  width: 100%;
  background: $menu-color;
}

.chat-notif {
  position: absolute;
  top:10px;
  right:5px;
  height:20px;
  width:20px;
  background:$chatColor;
  border-radius:50%;
  display:flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  font-family: $font-bold;
  font-size:12px;
}

.chat-notif-resp {
  right:20px;
}

.chat-notif-burger {
  top:-15px;
  right:-10px;
  z-index:999;
}

/* Account */

.menu-user {
  position: relative;
  width: 250px;
  max-width: 250px;
}

.menu-user-main {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $menu-color;
  height: 85px;
  cursor: pointer;
}

.menu-user .avatar img {
  height: 45px;
  width: 45px;
}

.menu-user .avatar {
  height: 45px;
  width: 45px;
  min-width: 45px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 20px;
  background-position: center !important ;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border:1px solid $main-color;
}

.avatar-default {
  height: 45px;
  width: 45px;
  min-width: 45px;
  margin-right: 20px;
  border-radius: 50%;
  color: #fff;
  font-family: $font-bold;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.menu-user-sub {
  position: absolute;
  top: 85px;
  right: 0;
  left: 0;
  z-index: 99;
}

.menu-user-sub a {
  text-decoration: none;
}

.submenu-item {
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: $font-bold;
  font-weight: 700;
}

a.submenu-item {
  text-decoration: none;
}

.submenu-item:hover {
  background: $main-color;
}

.submenu-item:hover .icon-submenu:before {
  color: #fff;
}

.submenu-item:hover .submenu-text {
  color: #fff;
}

.submenu-item:last-child {
  border-bottom: 1px solid $menu-color;
}

.icon-submenu {
  margin: 0 20px;
}

.icon-submenu:before {
  font-size: 18px;
  color: $main-color;
}

.submenu-text {
  color: $text-color;
  letter-spacing: 1px;
  text-decoration: none;
}

.logout-item {
  background: $menu-color;
}

.logout-item .icon-submenu:before {
  color: #fff;
}

.logout-item .submenu-text {
  color: #fff;
}

.user-infos {
  margin-right: 20px;
}

.user-name {
  font-size: 15px;
  font-family: $font-bold;
  font-weight: 700;
  text-align: center;
  color: $main-color;
}

.user-company {
  font-size: 10px;
  font-family: $font-bold;
  font-weight: 700;
  text-align: center;
  color: $text-color;
  text-transform: uppercase;
}

.toggle-menu {
  display: inline-block;
  height: 0;
  width: 0;
  border-right: 6px solid transparent;
  border-bottom: 10px solid $menu-color;
  border-left: 6px solid transparent;
  transform: rotate(180deg);
}

.toggle-menu.reverse {
  transform: rotate(0);
}

.menu-resp-logout {
  background: $menu-color;
  margin-top: -5px;
}

.menu-resp-logout .icon-menu:before {
  color: #fff;
}

.menu-resp-logout .menu-text {
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .menu-header-resp {
    display: flex;
  }

  .menu-header {
    display: none;
  }
}
</style>